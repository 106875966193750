<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-4">Data Akuntansi</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button
          type="button"
          class="btn btn-outline-primary"
          @click="showModal('create', {})"
        >
          Tambah
        </a-button>
        <a-button class="btn btn-outline-success ml-2" @click="getAllData(true)"
          >Refresh Data</a-button
        >
        <a-button class="btn btn-outline-success float-right" @click="exportExcel"
          >Export Data</a-button
        >
      </template>
      <!-- :scroll="{ x:0, y: 400 }" -->
      <a-tabs @change="callback">
        <a-tab-pane key="1" tab="1. Aktiva">
          <a-table
            :loading="tabloading"
            :scroll="{ x: 0, y: 400 }"
            :columns="columns"
            :dataSource="data"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <strong v-if="record.jenis === 'I'">{{ text }}</strong>
                <label v-else>{{ text }}</label>
              </template>
            </template>
            <!-- <div slot='keterangan' slot-scope="text" v-html="text"></div> -->
            <div slot="jenis" slot-scope="text">
              <strong v-if="text === 'I'">{{
                text === "I" ? "Induk" : "Detail"
              }}</strong>
              <label v-else>{{ text === "I" ? "Induk" : "Detail" }}</label>
            </div>
            <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" color="blue" :key="tag">{{
                tag
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <a
                href="javascript:void(0)"
                class="text-warning"
                @click="showModal('update', record)"
              >
                <a-icon type="edit"></a-icon>
              </a>
              <a-divider type="vertical" />
              <a
                href="javascript:void(0)"
                class="text-danger"
                @click="showDeleteConfirm(record)"
              >
                <a-icon type="delete"></a-icon>
              </a>
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <span slot="jenis_identitas" slot-scope="text, record">
              {{
                findRelationData(
                  record,
                  "masterJenisIndentitas",
                  "id",
                  "jenis_identitas",
                  "keterangan"
                )
              }}
            </span>
            <span slot="tgl" slot-scope="text, record">
              {{ formatDate(record.tgl) }}
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="2. Pasiva">
          <a-table
            :loading="tabloading"
            :scroll="{ x: 0, y: 400 }"
            :columns="columns"
            :dataSource="data"
            :pagination="{
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <strong v-if="record.jenis === 'I'">{{ text }}</strong>
                <label v-else>{{ text }}</label>
              </template>
            </template>
            <!-- <div slot='keterangan' slot-scope="text" v-html="text"></div> -->
            <div slot="jenis" slot-scope="text">
              <strong v-if="text === 'I'">{{
                text === "I" ? "Induk" : "Detail"
              }}</strong>
              <label v-else>{{ text === "I" ? "Induk" : "Detail" }}</label>
            </div>
            <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" color="blue" :key="tag">{{
                tag
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <a
                href="javascript:void(0)"
                class="text-warning"
                @click="showModal('update', record)"
              >
                <a-icon type="edit"></a-icon>
              </a>
              <a-divider type="vertical" />
              <a
                href="javascript:void(0)"
                class="text-danger"
                @click="showDeleteConfirm(record)"
              >
                <a-icon type="delete"></a-icon>
              </a>
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <span slot="jenis_identitas" slot-scope="text, record">
              {{
                findRelationData(
                  record,
                  "masterJenisIndentitas",
                  "id",
                  "jenis_identitas",
                  "keterangan"
                )
              }}
            </span>
            <span slot="tgl" slot-scope="text, record">
              {{ formatDate(record.tgl) }}
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="3. Modal">
          <a-table
            :loading="tabloading"
            :scroll="{ x: 0, y: 400 }"
            :columns="columns"
            :dataSource="data"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <strong v-if="record.jenis === 'I'">{{ text }}</strong>
                <label v-else>{{ text }}</label>
              </template>
            </template>
            <!-- <div slot='keterangan' slot-scope="text" v-html="text"></div> -->
            <div slot="jenis" slot-scope="text">
              <strong v-if="text === 'I'">{{
                text === "I" ? "Induk" : "Detail"
              }}</strong>
              <label v-else>{{ text === "I" ? "Induk" : "Detail" }}</label>
            </div>
            <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" color="blue" :key="tag">{{
                tag
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <a
                href="javascript:void(0)"
                class="text-warning"
                @click="showModal('update', record)"
              >
                <a-icon type="edit"></a-icon>
              </a>
              <a-divider type="vertical" />
              <a
                href="javascript:void(0)"
                class="text-danger"
                @click="showDeleteConfirm(record)"
              >
                <a-icon type="delete"></a-icon>
              </a>
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <span slot="jenis_identitas" slot-scope="text, record">
              {{
                findRelationData(
                  record,
                  "masterJenisIndentitas",
                  "id",
                  "jenis_identitas",
                  "keterangan"
                )
              }}
            </span>
            <span slot="tgl" slot-scope="text, record">
              {{ formatDate(record.tgl) }}
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="4" tab="4. Pendapatan">
          <a-table
            :loading="tabloading"
            :scroll="{ x: 0, y: 400 }"
            :columns="columns"
            :dataSource="data"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <strong v-if="record.jenis === 'I'">{{ text }}</strong>
                <label v-else>{{ text }}</label>
              </template>
            </template>
            <!-- <div slot='keterangan' slot-scope="text" v-html="text"></div> -->
            <div slot="jenis" slot-scope="text">
              <strong v-if="text === 'I'">{{
                text === "I" ? "Induk" : "Detail"
              }}</strong>
              <label v-else>{{ text === "I" ? "Induk" : "Detail" }}</label>
            </div>
            <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" color="blue" :key="tag">{{
                tag
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <a
                href="javascript:void(0)"
                class="text-warning"
                @click="showModal('update', record)"
              >
                <a-icon type="edit"></a-icon>
              </a>
              <a-divider type="vertical" />
              <a
                href="javascript:void(0)"
                class="text-danger"
                @click="showDeleteConfirm(record)"
              >
                <a-icon type="delete"></a-icon>
              </a>
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <span slot="jenis_identitas" slot-scope="text, record">
              {{
                findRelationData(
                  record,
                  "masterJenisIndentitas",
                  "id",
                  "jenis_identitas",
                  "keterangan"
                )
              }}
            </span>
            <span slot="tgl" slot-scope="text, record">
              {{ formatDate(record.tgl) }}
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="5" tab="5. Biaya">
          <a-table
            :loading="tabloading"
            :scroll="{ x: 0, y: 400 }"
            :columns="columns"
            :dataSource="data"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <strong v-if="record.jenis === 'I'">{{ text }}</strong>
                <label v-else>{{ text }}</label>
              </template>
            </template>
            <!-- <div slot='keterangan' slot-scope="text" v-html="text"></div> -->
            <div slot="jenis" slot-scope="text">
              <strong v-if="text === 'I'">{{
                text === "I" ? "Induk" : "Detail"
              }}</strong>
              <label v-else>{{ text === "I" ? "Induk" : "Detail" }}</label>
            </div>
            <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" color="blue" :key="tag">{{
                tag
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <a
                href="javascript:void(0)"
                class="text-warning"
                @click="showModal('update', record)"
              >
                <a-icon type="edit"></a-icon>
              </a>
              <a-divider type="vertical" />
              <a
                href="javascript:void(0)"
                class="text-danger"
                @click="showDeleteConfirm(record)"
              >
                <a-icon type="delete"></a-icon>
              </a>
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <span slot="jenis_identitas" slot-scope="text, record">
              {{
                findRelationData(
                  record,
                  "masterJenisIndentitas",
                  "id",
                  "jenis_identitas",
                  "keterangan"
                )
              }}
            </span>
            <span slot="tgl" slot-scope="text, record">
              {{ formatDate(record.tgl) }}
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="6" tab="6. Rek. Administratif">
          <a-table
            :loading="tabloading"
            :scroll="{ x: 0, y: 400 }"
            :columns="columns"
            :dataSource="data"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <strong v-if="record.jenis === 'I'">{{ text }}</strong>
                <label v-else>{{ text }}</label>
              </template>
            </template>
            <!-- <div slot='keterangan' slot-scope="text" v-html="text"></div> -->
            <div slot="jenis" slot-scope="text">
              <strong v-if="text === 'I'">{{
                text === "I" ? "Induk" : "Detail"
              }}</strong>
              <label v-else>{{ text === "I" ? "Induk" : "Detail" }}</label>
            </div>
            <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" color="blue" :key="tag">{{
                tag
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <a
                href="javascript:void(0)"
                class="text-warning"
                @click="showModal('update', record)"
              >
                <a-icon type="edit"></a-icon>
              </a>
              <a-divider type="vertical" />
              <a
                href="javascript:void(0)"
                class="text-danger"
                @click="showDeleteConfirm(record)"
              >
                <a-icon type="delete"></a-icon>
              </a>
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <span slot="jenis_identitas" slot-scope="text, record">
              {{
                findRelationData(
                  record,
                  "masterJenisIndentitas",
                  "id",
                  "jenis_identitas",
                  "keterangan"
                )
              }}
            </span>
            <span slot="tgl" slot-scope="text, record">
              {{ formatDate(record.tgl) }}
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <a-modal
      v-model="formvisible"
      title="Form Akuntansi"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="footer">
        <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
        <a-button class="" type="primary" @click="handleSubmit"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label class="mt-1">Kode</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-input
            class=""
            v-model="modalInput.kode"
            placeholder="Kode"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Keterangan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            v-model="modalInput.keterangan"
            placeholder="Keterangan"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Jenis</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-select class="" style="width: 200px;" v-model="modalInput.jenis">
            <a-select-option value="I">Induk</a-select-option>
            <a-select-option value="D">Detail</a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    // fixed: 'left',
    width: 20,
  },
  {
    title: 'Keterangan',
    dataIndex: 'label',
    width: 120,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.label.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  // {
  //   title: 'Kode',
  //   dataIndex: 'kode',
  //   width: 50,
  //   // fixed: 'left',
  //   scopedSlots: {
  //     filterDropdown: 'filterDropdown',
  //     filterIcon: 'filterIcon',
  //     customRender: 'customRender',
  //   },
  //   onFilter: (value, record) =>
  //     record.kode.toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => {
  //         this.searchInput.focus()
  //       }, 0)
  //     }
  //   },
  // },
  // {
  //   title: 'Keterangan',
  //   dataIndex: 'keterangan',
  //   width: 150,
  //   // fixed: 'left',
  //   scopedSlots: {
  //     filterDropdown: 'filterDropdown',
  //     filterIcon: 'filterIcon',
  //     customRender: 'customRender',
  //   },
  //   onFilter: (value, record) =>
  //     record.keterangan.toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => {
  //         this.searchInput.focus()
  //       }, 0)
  //     }
  //   },
  // },
  {
    title: 'Jenis',
    dataIndex: 'jenis',
    width: 20,
    // fixed: 'left',
    scopedSlots: {
      customRender: 'jenis',
    },
  },
]
/* End script Kolom atau header dari table */

export default {
  components: {},
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      activeKey: '1',
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data: [],
      columns,
      backupData: [],
      datenow: '',
      tabloading: false,
      tablist: {
        1: 'aktiva',
        2: 'pasiva',
        3: 'modal',
        4: 'pendapatan',
        5: 'biaya',
        6: 'administratif',
      },
      componentKey: 0,

      // Modal property
      action: null,
      formvisible: false,
      editdata: {},
      modalInput: {
        kode: '',
        keterangan: '',
        jenis: '',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getAgamaF()
  },
  methods: {
    moment,
    async callback(key) {
      // console.log('this.tablist[key]', this.tablist[key])
      this.activeKey = key
      if (!this.tabloading) {
        this.data = []
        this.tabloading = true
        var response = await lou.customUrlGet2(
          'master_akuntansi/' + this.tablist[key],
        )
        if (response) {
          this.tabloading = false
          // setTimeout(() => {
          var that = this
          response.data.forEach((element) => {
            var nobj = {
              label: element.kode + ' - ' + element.keterangan,
              ...element,
            }
            that.data.push(nobj)
          })
          // }, 100)
          // this.backupData = this.$g.clone(response.data)
        }
        this.tabloading = false
      }
      // console.log('key', key)
    },
    showModal(action, data) {
      this.formvisible = true
      this.action = action
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        // this.editdata = data
      }
    },
    async getAllData(onclick = false) {
      var response = await lou.customUrlGet2(
        'master_akuntansi/' + this.tablist[this.activeKey],
      )
      if (response) {
        this.data = []
        response.data.forEach((element) => {
          var nobj = {
            label: element.kode + ' - ' + element.keterangan,
            ...element,
          }
          this.data.push(nobj)
        })
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
        // this.backupData = this.$g.clone(response.data)
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'master_akuntansi/' + deldata.id,
          )
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('master_akuntansi', fd)
      } else {
        res = await lou.customUrlPost2('master_akuntansi', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.modalInput = {
        kode: '',
        keterangan: '',
        jenis: '',
      }
    },

    // Export Function

    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      const Aktiva = workbook.addWorksheet('1.Aktiva')
      const Pasiva = workbook.addWorksheet('2.Pasiva')
      const Modal = workbook.addWorksheet('3.Modal')
      const Pendapatan = workbook.addWorksheet('4.Pendapatan')
      const Biaya = workbook.addWorksheet('5.Biaya')
      const Rek = workbook.addWorksheet('6.Rek Administratif')

      // Table Data

      var resaktiva = await lou.customUrlGet2('master_akuntansi/aktiva')
      var respasiva = await lou.customUrlGet2('master_akuntansi/pasiva')
      var resmodal = await lou.customUrlGet2('master_akuntansi/modal')
      var respendapatan = await lou.customUrlGet2('master_akuntansi/pendapatan')
      var resbiaya = await lou.customUrlGet2('master_akuntansi/biaya')
      var resadministratif = await lou.customUrlGet2('master_akuntansi/administratif')

      if (resaktiva) {
        var tresaktiva = []
        resaktiva.data.forEach(element => {
          var rsj = {
            ...element,
            label: element.kode + ' - ' + element.keterangan,
            jenis: element.jenis === 'I' ? 'Induk' : 'Detail',
          }
          tresaktiva.push(rsj)
        })
        this.isiWorksheet(Aktiva, tresaktiva)
      }
      if (respasiva) {
        var trespasiva = []
        respasiva.data.forEach(element => {
          var rsj = {
            ...element,
            label: element.kode + ' - ' + element.keterangan,
            jenis: element.jenis === 'I' ? 'Induk' : 'Detail',
          }
          trespasiva.push(rsj)
        })
        this.isiWorksheet(Pasiva, trespasiva)
      }
      if (resmodal) {
        var tresmodal = []
        resmodal.data.forEach(element => {
          var rsj = {
            ...element,
            label: element.kode + ' - ' + element.keterangan,
            jenis: element.jenis === 'I' ? 'Induk' : 'Detail',
          }
          tresmodal.push(rsj)
        })
        this.isiWorksheet(Modal, tresmodal)
      }
      if (respendapatan) {
        var trespendapatan = []
        respendapatan.data.forEach(element => {
          var rsj = {
            ...element,
            label: element.kode + ' - ' + element.keterangan,
            jenis: element.jenis === 'I' ? 'Induk' : 'Detail',
          }
          trespendapatan.push(rsj)
        })
        this.isiWorksheet(Pendapatan, trespendapatan)
      }
      if (resbiaya) {
        var tresbiaya = []
        resbiaya.data.forEach(element => {
          var rsj = {
            ...element,
            label: element.kode + ' - ' + element.keterangan,
            jenis: element.jenis === 'I' ? 'Induk' : 'Detail',
          }
          tresbiaya.push(rsj)
        })
        this.isiWorksheet(Biaya, tresbiaya)
      }
      if (resadministratif) {
        var tresadministratif = []
        resadministratif.data.forEach(element => {
          var rsj = {
            ...element,
            label: element.kode + ' - ' + element.keterangan,
            jenis: element.jenis === 'I' ? 'Induk' : 'Detail',
          }
          tresadministratif.push(rsj)
        })
        this.isiWorksheet(Rek, tresadministratif)
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master Akuntansi.xlsx'
      NProgress.done()
      link.click()
    },

    isiWorksheet(worksheet, data) {
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      var column = this.$g.clone(this.columns)
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }
    },
  },
}
</script>

<style scoped>
.ant-tabs-bar {
  margin: unset !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
